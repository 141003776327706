const VehicleId = 'vehicle-id'
const ReportId = 'report-id'
const ReportTitle = 'report-title'
const RoleId = 'role-id'
const RoleKey = 'role-key'
const RoleSubTitle = 'role-sub-title'
const ShareAccountId = 'share-account-id'
const DetailActive = 'detail-active'
const FleetId = 'fleet-id'
const FactoryId = 'factory-id'
const PreFleetTitle = 'pre-fleet-title'
const PreSubFleetTitle = 'pre-sub-fleet-title'
const FromVehicles = 'from-vehicles'
const ReportPermission = 'report-permission'
const PrealarmId = 'prealarm-id'
const PrealarmFleetId = 'prealarm-fleet-id'

export function getPrealarmId() {
  return Number(sessionStorage.getItem(PrealarmId))
}

export function setPrealarmId(id) {
  return sessionStorage.setItem(PrealarmId, id)
}

export function removePrealarmId() {
  return sessionStorage.removeItem(PrealarmId)
}

export function getPrealarmFleetId() {
  return Number(sessionStorage.getItem(PrealarmFleetId))
}

export function setPrealarmFleetId(id) {
  return sessionStorage.setItem(PrealarmFleetId, id)
}

export function removePrealarmFleetId() {
  return sessionStorage.removeItem(PrealarmFleetId)
}

export function getReportPermission() {
  return Number(sessionStorage.getItem(ReportPermission))
}

export function setReportPermission(id) {
  return sessionStorage.setItem(ReportPermission, id)
}

export function removeReportPermission() {
  return sessionStorage.removeItem(ReportPermission)
}

export function getFromVehicles() {
  return sessionStorage.getItem(FromVehicles)
}

export function setFromVehicles(id) {
  return sessionStorage.setItem(FromVehicles, id)
}

export function removeFromVehicles() {
  return sessionStorage.removeItem(FromVehicles)
}

export function getPreSubFleetTitle() {
  return sessionStorage.getItem(PreSubFleetTitle)
}

export function setPreSubFleetTitle(id) {
  return sessionStorage.setItem(PreSubFleetTitle, id)
}

export function removePreSubFleetTitle() {
  return sessionStorage.removeItem(PreSubFleetTitle)
}

export function getPreFleetTitle() {
  return sessionStorage.getItem(PreFleetTitle)
}

export function setPreFleetTitle(id) {
  return sessionStorage.setItem(PreFleetTitle, id)
}

export function removePreFleetTitle() {
  return sessionStorage.removeItem(PreFleetTitle)
}

export function getFactoryId() {
  return sessionStorage.getItem(FactoryId)
}

export function setFactoryId(id) {
  return sessionStorage.setItem(FactoryId, id)
}

export function removeFactoryId() {
  return sessionStorage.removeItem(FactoryId)
}

export function getFleetId() {
  return sessionStorage.getItem(FleetId)
}

export function setFleetId(id) {
  return sessionStorage.setItem(FleetId, id)
}

export function removeFleetId() {
  return sessionStorage.removeItem(FleetId)
}

export function getDetailActive() {
  return sessionStorage.getItem(DetailActive)
}

export function setDetailActive(id) {
  return sessionStorage.setItem(DetailActive, id)
}

export function removeDetailActive() {
  return sessionStorage.removeItem(DetailActive)
}

export function getReportTitle() {
  return sessionStorage.getItem(ReportTitle)
}

export function setReportTitle(id) {
  return sessionStorage.setItem(ReportTitle, id)
}

export function removeReportTitle() {
  return sessionStorage.removeItem(ReportTitle)
}

export function getRoleSubTitle() {
  return sessionStorage.getItem(RoleSubTitle)
}

export function setRoleSubTitle(id) {
  return sessionStorage.setItem(RoleSubTitle, id)
}

export function removeRoleSubTitle() {
  return sessionStorage.removeItem(RoleSubTitle)
}

export function getVehicleId() {
  return sessionStorage.getItem(VehicleId)
}

export function setVehicleId(id) {
  return sessionStorage.setItem(VehicleId, id)
}

export function removeVehicleId() {
  return sessionStorage.removeItem(VehicleId)
}

export function getReportId() {
  return sessionStorage.getItem(ReportId)
}

export function setReportId(id) {
  return sessionStorage.setItem(ReportId, id)
}

export function removeReportId() {
  return sessionStorage.removeItem(ReportId)
}

export function getRoleId() {
  return sessionStorage.getItem(RoleId)
}

export function setRoleId(id) {
  return sessionStorage.setItem(RoleId, id)
}

export function removeRoleId() {
  return sessionStorage.removeItem(RoleId)
}

export function getRoleKey() {
  return sessionStorage.getItem(RoleKey)
}

export function setroleKey(key) {
  return sessionStorage.setItem(RoleKey, key)
}

export function removeRoleKey() {
  return sessionStorage.removeItem(RoleKey)
}

export function getShareAccountId() {
  return sessionStorage.getItem(ShareAccountId)
}

export function setShareAccountId(id) {
  return sessionStorage.setItem(ShareAccountId, id)
}

export function removeShareAccountId() {
  return sessionStorage.removeItem(ShareAccountId)
}
