<template>
  <div class="tbb-header flex flex-jc-between flex-ai-center">
    <div class="tbb-header-l flex flex-ai-center">
      <el-tooltip
        v-if="isButton && sidebarRouters.length > 0"
        class="item"
        effect="dark"
        :content="$t('common.return')"
        placement="top"
      >
        <el-button
          class="tbb-header-btn"
          type="info"
          icon="el-icon-arrow-left"
          @click="handleClickBack"
        />
      </el-tooltip>
      <!-- 二级报表 -->
      <p class="tbb-header-title" v-if="currentPath == '/data/report/page'">
        {{ setReportTitle }}
      </p>
      <!-- 二级角色 -->
      <p
        class="tbb-header-title"
        v-else-if="currentPath == '/role/alocation/page'"
      >
        {{ setTitle }}
        <span v-show="setRoleSubTitle && isShowRoleSubTitle">
          : {{ setRoleSubTitle }}
        </span>
      </p>

      <!-- 预警二级 -->
      <p
        class="tbb-header-title"
        v-else-if="currentPath == '/prealarm/prefleet/page'"
      >
        {{ setPreFleetTitle }}
      </p>
      <!-- 预警三级 -->
      <p
        class="tbb-header-title"
        v-else-if="currentPath == '/prealarm/presubfleet/page'"
      >
        {{ setTitle }}
        <span class="font-color-666">({{ setPreSubFleetTitle }})</span>
      </p>

      <p class="tbb-header-title" v-else>{{ setTitle }}</p>

      <p
        class="tbb-header-num"
        v-if="listLenght > 0 && !isListLenght && !showFleetMenu"
      >
        ({{ listLenght }})
      </p>

      <!-- 车辆列表 -->
      <el-dropdown
        v-if="showFleetMenu && setHeaderMent.length > 0"
        trigger="hover"
        :placement="'bottom'"
        :append-to-body="false"
        @command="handleFleetCommand"
      >
        <div class="flex flex-ai-center">
          <p class="tbb-header-num">
            ({{ factoryNameText }})
            <span v-show="listLenght > 0">({{ listLenght }})</span>
          </p>
          <i
            class="el-icon-caret-right cursor-pointer font-16 font-color-white mt-4"
          />
        </div>
        <el-dropdown-menu slot="dropdown" class="tbb-dropdown-menu">
          <el-dropdown-item
            :command="item"
            v-for="(item, index) in setHeaderMent"
            :key="index"
          >
            {{ item.factoryName }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>

    <div class="tbb-header-r flex flex-ai-center flex-jc-end" v-if="isSetting">
      <div class="tbb-header-notice cursor-pointer">
        <svg-icon icon-class="notice" class="tbb-notice-icon" />
        <div class="tbb-notice-tip"></div>
        <div class="tbb-header-line"></div>
      </div>
      <div
        class="tbb-header-user flex flex-direction-column flex-jc-between flex-ai-end"
      >
        <p
          class="tbb-header-user-name text-d-underline cursor-pointer"
          @click="handleClickAvatar"
        >
          {{ userInfo.name }}
        </p>
        <p class="tbb-header-user-role">
          {{ userInfo.roleList[0].roleName }}
        </p>
      </div>
      <el-tooltip
        class="item"
        effect="dark"
        :content="$t('common.setting')"
        placement="top"
      >
        <div
          class="tbb-header-avatar cursor-pointer"
          @click="handleClickAvatar"
        >
          <img
            v-if="userInfo.header"
            :src="showImage(userInfo.header)"
            alt=""
          />
          <img v-else src="@/assets/images/avatar.jpg" alt="" />
        </div>
      </el-tooltip>

      <!-- <el-dropdown
        trigger="hover"
        :placement="'bottom-end'"
        :append-to-body="false"
        @command="handleCommand"
      >
        <div class="tbb-header-avatar cursor-pointer">
          <img src="@/assets/images/avatar.jpg" alt="" />
        </div>
        <el-dropdown-menu
          slot="dropdown"
          class="tbb-dropdown-menu log-out-dropdown-menu"
        >
          <el-dropdown-item command="1">
            {{ $t('dialog.out.title') }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown> -->

      <OperationDialog
        :show="showOperationDialog"
        :opera-info="operaInfo"
        :dec-info="'dialog.out.out_dec'"
        @cancel="handleOperationCancel"
        @confirm="handelOperationConfirm"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  hearderPathIsBtn,
  routerBackList,
  RoterVehiclesBackList
} from '../utils/menu'
import {
  getFactoryId,
  getFromVehicles,
  setFactoryId,
  removeRoleSubTitle,
  removeReportTitle,
  removeDetailActive,
  removeRoleKey,
  removeRoleId,
  removeReportPermission
} from '@/utils/session'

export default {
  name: 'TbbHeader',
  computed: {
    ...mapGetters([
      'userInfo',
      'setTitle',
      'setReportTitle',
      'setRoleSubTitle',
      'setPreFleetTitle',
      'setPreSubFleetTitle',
      'setHeaderMent',
      'listLenght',
      'sidebarRouters'
    ])
  },
  data() {
    return {
      isButton: false,
      isShowRoleSubTitle: false,
      isListLenght: false,
      isSetting: false,
      isShowHeaderList: ['/home'],
      showOperationDialog: false,
      showFleetMenu: false,
      factoryNameText: '',
      operaInfo: {
        title: 'dialog.out.title',
        operaName: '',
        operaId: 0
      },
      currentPath: ''
    }
  },
  watch: {
    $route: {
      handler(route) {
        this.currentPath = route.path
        this.setHeaderFun(route)
      },
      immediate: true
    },
    setHeaderMent: {
      handler(newM) {
        let factoryId = this.setFactoryId || getFactoryId()
        if (newM.length > 0) {
          if (factoryId > 0) {
            newM.map((item) => {
              if (item.factoryId == factoryId) {
                this.factoryNameText = item.factoryName
              }
            })
          } else {
            this.factoryNameText = newM[0].factoryName
          }
        }
      },
      immediate: true
    }
  },

  methods: {
    showImage(url) {
      return process.env.VUE_APP_FILE_BASE_DOMAIN + url
    },
    setHeaderFun(route) {
      this.showFleetMenu = route.path == '/vehicles/list' ? true : false
      this.isSetting = route.path == '/setting/set' ? false : true
      let routeP = route.path.split('/')[2]
      this.isShowRoleSubTitle = routeP == 'alocation' ? true : false
      this.isButton = hearderPathIsBtn.indexOf(routeP) > -1

      this.isListLenght = this.isShowHeaderList.indexOf(route.path) > -1

      if (!this.showFleetMenu) {
        this.$store.dispatch('setFactoryId', 0)
        this.$store.dispatch('setFleetId', 0)
      }
    },
    handleClickBack() {
      let fromVehicles = getFromVehicles()
      if (fromVehicles) {
        if (this.currentPath != '/vehicles/airconditioner/page') {
          this.setRoterVehiclesBack(fromVehicles)
        } else {
          this.$router.push({ path: '/vehicles/subdetail/page' })
        }
      } else {
        this.setRoterBack()
      }

      this.$store.dispatch('listLenght', 0)
      this.$store.dispatch('setRoleSubTitle', '')
      this.$store.dispatch('setReportTitle', '')

      removeRoleSubTitle()
      removeReportTitle()
      removeDetailActive()
      removeRoleKey()
      removeRoleId()
      removeReportPermission()
    },
    setRoterBack() {
      routerBackList.map((item) => {
        if (item.originalLink == this.currentPath) {
          this.$router.push({ path: item.superiorLink })
        }
      })
    },
    setRoterVehiclesBack(key) {
      RoterVehiclesBackList.map((item) => {
        if (item.fromKey == key) {
          this.$router.push({ path: item.superiorLink })
        }
      })
    },
    handleCommand(command) {
      if (command == 1) {
        this.showOperationDialog = true
      }
    },
    handleFleetCommand(command) {
      this.factoryNameText = command.factoryName
      setFactoryId(command.factoryId)
      this.$store.dispatch('setFactoryId', command.factoryId)
      this.$store.dispatch('setFleetId', 0)
    },
    handleOperationCancel(val) {
      this.showOperationDialog = val
    },
    handleClickAvatar() {
      this.$router.push({ path: '/setting/set' })
    },
    handelOperationConfirm() {
      this.$store.dispatch('LogOut').then(() => {
        location.href = '/login'
        this.handleOperationCancel(false)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.tbb-header {
  .tbb-header-l {
    .tbb-header-btn {
      margin-right: 20px;
    }
    .tbb-header-title {
      margin-right: 4px;
      font-size: 24px;
      color: #ffffff;
    }
    .tbb-header-num {
      font-size: 24px;
      color: #666666;
    }
  }
  .tbb-header-r {
    .tbb-header-notice {
      display: none;
      position: relative;
      .tbb-notice-icon {
        font-size: 32px;
        margin-top: -8px;
      }
      .tbb-notice-tip {
        position: absolute;
        width: 6px;
        height: 6px;
        background: #ffa800;
        border-radius: 50%;
        top: 0px;
        right: 7px;
      }
      .tbb-header-line {
        position: absolute;
        top: 0px;
        right: -10px;
        width: 1px;
        height: 16px;
        background: #707070;
        opacity: 0.6;
      }
    }
  }
  .tbb-header-user {
    margin-left: 24px;
    .tbb-header-user-role {
      font-size: 10px;
      color: #656565;
    }
  }
  .tbb-header-avatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 1px solid rgba(255, 255, 255, 0.27);
    margin-left: 12px;
    img {
      width: 100%;
      height: 100%;
      object-fit: fill;
      border-radius: 50%;
    }
  }
}

.log-out-dropdown-menu {
  .el-dropdown-menu__item {
    margin: 0;
    padding: 0;
    height: 40px;
    line-height: 40px;
    text-align: center;
    width: 100px;
    font-size: 14px;
  }
}
</style>
