<template>
  <div class="base-dialog">
    <el-dialog
      :visible.sync="show"
      :width="width"
      :modal="showModal"
      :show-close="showClose"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      :before-close="handleCancel"
      @close="handleCancel"
    >
      <div slot="title">
        <p class="base-dialog-title">{{ $t(info.title) }}</p>
        <p class="base-dialog-dec" v-if="info.dec">{{ $t(info.dec) }}</p>
      </div>
      <slot name="content"></slot>
      <div
        slot="footer"
        v-if="footerText || showCancel || showConfirm"
        :class="[
          'tbb-dialog-footer',
          footerText ? 'tbb-dialog-footer-text' : ''
        ]"
      >
        <slot name="footer-text"></slot>
        <div>
          <el-button
            v-if="showCancel"
            :disabled="cancelDis"
            @click="handleCancel"
          >
            {{ $t('button.cancel') }}
          </el-button>
          <el-button
            v-if="showConfirm"
            class="base-dialog-confirm"
            type="lightblue"
            :disabled="confirmDis"
            :loading="setDialogConfirmBtn"
            @click="handelConfirm"
          >
            {{ $t('button.confirm') }}
          </el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'BaseDialog',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    showCancel: {
      type: Boolean,
      default: true
    },
    showConfirm: {
      type: Boolean,
      default: true
    },
    showClose: {
      type: Boolean,
      default: true
    },
    width: {
      type: String,
      default: '500px'
    },
    info: {
      type: Object,
      default() {
        return {
          title: '',
          dec: ''
        }
      }
    },
    footerText: {
      type: Boolean,
      default: false
    },
    cancelDis: {
      type: Boolean,
      default: false
    },
    confirmDis: {
      type: Boolean,
      default: false
    },
    showModal: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters(['setDialogConfirmBtn'])
  },

  methods: {
    handleCancel() {
      this.$emit('cancel', false)
    },
    handelConfirm() {
      this.$emit('confirm')
    }
  }
}
</script>

<style lang="scss" scoped>
.el-button {
  padding: 0 20px;
}
.base-dialog-confirm,
.el-button--lightblue {
  width: 150px;
  height: 45px;
  font-family: HARMONYOS_SANS_BLACK;
}
.el-button--default {
  height: 43px;
}
.tbb-dialog-footer-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tbb-dialog-footer {
  padding: 0px 20px 20px 20px;
}
</style>
