import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './lang'
import MyMixin from './mixin'

import '@/utils/moment'
import '@/styles/index.scss'
import '@/assets/icons'
import './permission'
import '@/utils/component'

import directive from './directive'

import { numberToString, stringToNumber } from '@/utils/validate'

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'

import * as filters from '@/utils/filters/filters'

Vue.prototype.$numberToString = numberToString
Vue.prototype.$stringToNumber = stringToNumber

Vue.config.productionTip = false
Vue.use(ElementUI)
Vue.use(directive)
Vue.use(MyMixin)

Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key])
})

window.addEventListener('popstate', function () {
  history.pushState(null, null, document.URL)
})

new Vue({
  el: '#app',
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app')
