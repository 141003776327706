const hearderPathIsBtn = [
  'detail',
  'alarm',
  'alocation',
  'report',
  'set',
  'subdetail',
  'airconditioner',
  'prefleet',
  'presubfleet'
]
const footerPathIsBtn = [
  '/home',
  '/vehicles/subdetail/page',
  '/home/detail/page',
  '/share/detail/page',
  '/vehicles/airconditioner/page'
]

const routerBackList = [
  {
    originalLink: '/home/alarm/page',
    superiorLink: '/home'
  },
  {
    originalLink: '/home/detail/page',
    superiorLink: '/home'
  },
  {
    originalLink: '/setting/set',
    superiorLink: '/home'
  },
  {
    originalLink: '/role/alocation/page',
    superiorLink: '/users/role'
  },
  // {
  //   originalLink: '/vehicles/airconditioner/page',
  //   superiorLink: '/vehicles/subdetail/page'
  // },
  {
    originalLink: '/vehicles/subdetail/page',
    superiorLink: '/vehicles/list'
  },
  {
    originalLink: '/data/report/page',
    superiorLink: '/data/reports'
  },
  {
    originalLink: '/prealarm/prefleet/page',
    superiorLink: '/data/prealarm'
  },
  {
    originalLink: '/prealarm/presubfleet/page',
    superiorLink: '/prealarm/prefleet/page'
  }
]

const RoterVehiclesBackList = [
  {
    fromKey: 'presubfleet',
    superiorLink: '/prealarm/presubfleet/page'
  },
  {
    fromKey: 'group',
    superiorLink: '/vehicles/group'
  }
]

const asideMenuPath = [
  {
    childPath: 'alarm',
    parentPath: '/home'
  },
  {
    childPath: 'detail',
    parentPath: '/home'
  },
  {
    childPath: 'report',
    parentPath: '/data/reports'
  },
  {
    childPath: 'alocation',
    parentPath: '/users/role'
  },
  {
    childPath: 'subdetail',
    parentPath: '/vehicles/list'
  },
  {
    childPath: 'airconditioner',
    parentPath: '/vehicles/list'
  },
  {
    childPath: 'prefleet',
    parentPath: '/data/prealarm'
  },
  {
    childPath: 'presubfleet',
    parentPath: '/data/prealarm'
  }
]

const collectAsideMenuPath = [
  {
    childPath: 'alarm',
    parentPath: 'home'
  },
  {
    childPath: 'detail',
    parentPath: 'home'
  },
  {
    childPath: 'report',
    parentPath: 'reports'
  },
  {
    childPath: 'alocation',
    parentPath: 'role'
  },
  {
    childPath: 'subdetail',
    parentPath: 'list'
  },
  {
    childPath: 'airconditioner',
    parentPath: 'list'
  },
  {
    childPath: 'prefleet',
    parentPath: 'prealarm'
  },
  {
    childPath: 'presubfleet',
    parentPath: 'prealarm'
  }
]

export {
  hearderPathIsBtn,
  routerBackList,
  RoterVehiclesBackList,
  asideMenuPath,
  collectAsideMenuPath,
  footerPathIsBtn
}
