<template>
  <div class="tbb-set-btn ml-8" v-hasPermi="hasPer">
    <el-tooltip
      class="item"
      effect="dark"
      :content="tooltip"
      placement="top"
      v-if="tooltip"
    >
      <el-button class="set-btn" :disabled="disabled" @click="handleClick">
        <svg-icon
          :icon-class="icon"
          :disabled="disabled"
          :class="font"
        ></svg-icon>
      </el-button>
    </el-tooltip>
    <el-button class="set-btn" :disabled="disabled" @click="handleClick" v-else>
      <svg-icon
        :icon-class="icon"
        :disabled="disabled"
        class="font-32"
      ></svg-icon>
    </el-button>
  </div>
</template>

<script>
export default {
  name: 'SetIconBtn',
  props: {
    hasPer: {
      type: Array,
      default: () => []
    },
    icon: {
      type: String,
      default: 'reset'
    },
    tooltip: {
      type: String,
      default: ''
    },
    font: {
      type: String,
      default: 'font-32'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleClick() {
      this.$emit('click')
    }
  }
}
</script>
<style lang="scss" scoped>
.tbb-set-btn {
  .set-btn {
    background: #303235;
    border: none;
    height: 36px !important;
    width: 36px;
    border-radius: 6px;
    padding: 0;
    &:focus {
      background-color: #303235 !important;
    }
    &.is-disabled {
      background-color: #303235 !important;
    }
  }
}
</style>
