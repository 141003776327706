<template>
  <div class="tbb-aside-container">
    <div class="tbb-asidde-logo flex flex-jc-center">
      <img
        class="mb-36 cursor-pointer flex flex-self-center"
        src="@/assets/images/logo.png"
        @click="handleClickHome('/home')"
      />
    </div>
    <el-scrollbar wrap-class="scrollbar-wrapper" :hide="true">
      <el-menu
        class="el-menu-vertical-demo"
        background-color="rgba(255, 255, 255, 0)"
        text-color="#fff"
        :router="true"
        :default-active="defaultActive"
      >
        <div v-for="(item, index) in sidebarRouters" :key="index">
          <!-- v-if="!item.hidden" -->
          <el-menu-item-group v-if="!item.hidden">
            <span v-if="item.children && item.children.length > 0">
              <p class="tbb-aside-item-title">
                {{ item.name }}
              </p>
              <span v-for="(child, index) in item.children" :key="index">
                <el-menu-item
                  :index="resolvePath(child.path, item.path)"
                  @click="handleClick(child.path)"
                  v-if="!child.hidden"
                >
                  <!-- v-if="!child.hidden" -->
                  <div class="flex flex-ai-center" v-if="!child.hidden">
                    <svg-icon
                      :icon-class="child.meta.icon"
                      class="tbb-menu-icon"
                    />
                    <p>{{ child.name }}</p>
                  </div>
                  <div
                    v-if="tbbActive == child.path"
                    class="tbb-meny-item-active"
                  ></div>
                </el-menu-item>
              </span>
            </span>
            <span v-else>
              <el-menu-item
                :index="resolvePath(item.path)"
                @click="handleClick(item.path)"
              >
                <div class="flex flex-ai-center">
                  <svg-icon
                    :icon-class="item.meta.icon"
                    class="tbb-menu-icon"
                  />
                  <p>{{ item.name }}</p>
                </div>
                <div
                  v-show="'/' + tbbActive == item.path"
                  class="tbb-meny-item-active"
                ></div>
              </el-menu-item>
            </span>
          </el-menu-item-group>
        </div>
      </el-menu>
    </el-scrollbar>
    <p class="version-bottom font-color-gray">{{ $t('version.version') }}</p>
  </div>
</template>

<script>
import path from 'path'
import { mapGetters } from 'vuex'
import { isExternal } from '@/utils/validate'
import { asideMenuPath } from '../utils/menu'
import { removeDetailActive, removeFleetId } from '@/utils/session'

export default {
  name: 'TbbAside',

  computed: {
    ...mapGetters(['sidebarRouters', 'indexPage'])
  },
  data() {
    return {
      tbbActive: '',
      defaultActive: ''
    }
  },
  watch: {
    $route: {
      handler(newT) {
        this.setDefaultActive(newT.path)
        this.setTbbActive(newT.path)
      },
      deep: true
    }
  },
  mounted() {
    this.setDefaultActive(this.$route.path)
    this.setTbbActive(this.$route.path)
  },

  methods: {
    handleClickHome(path) {
      this.$store.dispatch('listLenght', 0)
      this.$store.dispatch('setReportTitle', '')
      this.$router.push(path)
      removeFleetId()
    },
    setDefaultActive(path) {
      let pLength = path.split('/').length
      if (pLength <= 3) {
        this.defaultActive = path
      } else {
        this.setDefaultActiveFun(path)
      }
    },
    setDefaultActiveFun(path) {
      asideMenuPath.map((item) => {
        if (item.childPath == path.split('/')[2]) {
          return (this.defaultActive = item.parentPath)
        }
      })
    },
    setTbbActive(path) {
      let indexP = this.indexPage.split('/')[1]
      let otherP = this.setOtherP(path)

      if (indexP != otherP) {
        this.tbbActive = otherP
      } else {
        this.tbbActive = indexP
      }
    },
    setOtherP(path) {
      let pLength = path.split('/')

      if (pLength.length <= 2 || (pLength.length > 3 && pLength[1] == 'home')) {
        return pLength[1]
      } else {
        return pLength[2]
      }
    },
    handleClick(key) {
      this.$store.dispatch('listLenght', 0)
      this.$store.dispatch('setReportTitle', '')
      this.tbbActive = key.split('/').length > 1 ? key.split('/')[1] : key
      removeFleetId()
      removeDetailActive()
    },
    resolvePath(routePath, routeParent) {
      if (isExternal(routePath)) {
        return routePath
      }
      if (isExternal(routeParent)) {
        return routeParent
      }

      return routeParent
        ? path.resolve(routeParent, routePath)
        : path.resolve('/' + routePath)
    }
  }
}
</script>

<style lang="scss" scoped>
.tbb-aside-container {
  position: relative;
  height: 100%;
  .tbb-asidde-logo {
    width: 100%;
    img {
      width: 156px;
      height: 40px;
      object-fit: fill;
    }
  }
  .tbb-aside-item-title {
    font-size: 12px;
    color: #666666;
    padding-left: 20px;
  }
  .tbb-meny-item-active {
    width: 2px;
    height: 12px;
    background: #ffffff;
  }
  .tbb-menu-icon {
    font-size: 24px;
    margin-left: -4px;
    margin-right: 8px;
  }
}

:deep(.el-scrollbar) {
  .scrollbar-wrapper {
    height: calc(100vh - 140px);
    overflow-y: scroll;
    overflow-x: hidden;
    margin: 0px !important;
  }
  .el-scrollbar__bar {
    display: none;
  }
  .el-scrollbar:hover .el-scrollbar__bar {
    display: block;
  }
}
.version-bottom {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  font-size: 12px;
}
</style>
