import Vue from 'vue'
import VueRouter from 'vue-router'
import i18n from '@/lang'

Vue.use(VueRouter)

/* Layout */
import Layout from '@/layout'

export const constantRoutes = [
  {
    path: '/login',
    component: (resolve) => require(['@/views/login'], resolve),
    hidden: true
  },
  {
    path: '/register',
    component: (resolve) => require(['@/views/register'], resolve),
    hidden: true
  },
  {
    path: '/404',
    component: (resolve) => require(['@/views/error/404'], resolve),
    hidden: true
  },
  {
    path: '/share',
    component: (resolve) => require(['@/views/share'], resolve),
    hidden: true
  },
  {
    path: '/share/detail/page',
    component: (resolve) => require(['@/views/share/detail'], resolve),
    name: 'ShareDetail',
    meta: { title: i18n.t('menu.detail') }
  },
  {
    path: '',
    component: Layout,
    redirect: '/home',
    children: [
      {
        path: '/home',
        component: (resolve) => require(['@/views/home'], resolve),
        name: 'Home',
        meta: { title: i18n.t('menu.home') }
      },
      {
        path: '/home/alarm/page',
        component: (resolve) => require(['@/views/home/alarm'], resolve),
        name: 'Alarm',
        meta: { title: i18n.t('menu.alarm') }
      },
      {
        path: '/home/detail/page',
        component: (resolve) => require(['@/views/home/detail'], resolve),
        name: 'HomeDetail',
        meta: { title: i18n.t('menu.detail') }
      }
    ]
  },
  {
    path: '',
    component: Layout,
    children: [
      {
        path: '/vehicles/subdetail/page',
        component: (resolve) => require(['@/views/vehicles/detail'], resolve),
        name: 'VehiclesDetail',
        meta: { title: i18n.t('menu.detail') }
      },
      {
        path: '/vehicles/airconditioner/page',
        component: (resolve) =>
          require(['@/views/vehicles/detail/airconditioner'], resolve),
        name: 'AirConditioner',
        meta: { title: i18n.t('menu.airconditioner') }
      }
    ]
  },
  {
    path: '',
    component: Layout,
    children: [
      {
        path: '/data/report/page',
        component: (resolve) =>
          require(['@/views/data/reports/DefaultReport'], resolve),
        name: 'DefaultReport',
        meta: { title: i18n.t('menu.default_report') }
      }
    ]
  },
  {
    path: '',
    component: Layout,
    children: [
      {
        path: '/role/alocation/page',
        component: (resolve) =>
          require(['@/views/users/role/AllocationRole'], resolve),
        name: 'AllocationRole',
        meta: { title: i18n.t('menu.current_role') }
      }
    ]
  },
  {
    path: '',
    component: Layout,
    children: [
      {
        path: '/setting/set',
        component: (resolve) => require(['@/views/setting.vue'], resolve),
        name: 'Settings',
        meta: { title: i18n.t('menu.setting'), allowBack: false }
      }
    ]
  },
  {
    path: '',
    component: Layout,
    children: [
      {
        path: '/prealarm/prefleet/page',
        component: (resolve) =>
          require(['@/views/data/prealarm/Prefleet'], resolve),
        name: 'Prefleet',
        meta: { title: i18n.t('menu.prealarm_fleet') }
      },
      {
        path: '/prealarm/presubfleet/page',
        component: (resolve) =>
          require(['@/views/data/prealarm/PreSubfleet'], resolve),
        name: 'PreSubfleet',
        meta: { title: i18n.t('menu.prealarm_sub_fleet') }
      }
    ]
  }
]

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}
export default new VueRouter({
  mode: 'history',
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes,
  scrollBehavior: () => {
    history.pushState(null, null, document.URL)
  }
})
